import { useToggle } from 'react-use';

import { ArrowDownIcon, ArrowUpIcon } from '../icons/Arrows';

export function CollapsibleSection(props: {
  title: string;
  defaultOpened?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  right?: React.ReactNode;
}) {
  const [opened, toggleOpened] = useToggle(props.defaultOpened ?? true);

  return (
    <section className='w-full'>
      <div className='flex justify-between items-center'>
        <button
          disabled={props.disabled}
          type='button'
          className='btn text-tertiary flex items-center cursor-pointer'
          onClick={() => toggleOpened()}
        >
          <p className='text-sms font-medium mr-2'>{props.title}</p>
          {opened ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </button>
        {props.right}
      </div>
      {opened ? (
        <div className='mt-6 flex flex-col gap-4'>{props.children}</div>
      ) : null}
    </section>
  );
}
